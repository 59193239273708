﻿@import './Variable';

* {
    font-family: $foxtmanfontfamily;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.error {
    background-color: #f1c5c5 !important;
    color: black !important;

    .dx-texteditor-input-container {
        background-color: #f1c5c5 !important;
    }

    .dx-lookup-field-wrapper {
        background-color: #f1c5c5 !important;
    }
}

.dx-texteditor-container {
    padding-top: 0px !important;

    .dx-texteditor-input-container {
        padding-top: 0px !important;

        .dx-texteditor-input {
            padding: 5px 5px 5px !important;
            border: none;
        }
    }
}

.options-center {
    .dx-checkbox-container {
        justify-content: center;
        align-items: center;
        display: flex !important;
    }
}

#root {
    overflow: hidden;
}

html,
body,
#root,
#root {
    height: 100%
}

.positive {
    background-color: $foxymaincolour !important;
    border: $foxybtn_borderwidth solid $foxymaincolour;
    color: $foxyaccentcolour !important;
}

.negative {
    background-color: $foxyaccentcolour !important;
    border: $foxybtn_borderwidth solid $foxymaincolour;
    color: rgba(0, 0, 0, 0.75) !important;
}

.columnFlex {
    flex-direction: column !important;
}

.borderTop {
    border-top: $foxybtn_borderwidth solid $foxymaincolour;
}

.borderBottom {
    border-bottom: $foxybtn_borderwidth solid $foxymaincolour;
}

.borderLeft {
    border-left: $foxybtn_borderwidth solid $foxymaincolour;
}

.borderRight {
    border-right: $foxybtn_borderwidth solid $foxymaincolour;
}

.borderAll {
    border: $foxybtn_borderwidth solid $foxymaincolour !important;
}

.text-color {
    color: $foxymaincolour;
}

.borderRadius {
    border-radius: 5px !important;
}

.font-weight-bold {
    font-weight: bold
}

.heightcacl50 {
    height: calc(100% - 100px);
}

.height20px {
    height: 20px;
}

.height30px {
    height: 30px;
}

.height40px {
    height: 40px;
}

.height45px {
    height: 45px;
}

.height50px {
    height: 50px;
}

.height60px {
    height: 60px;
}

.height70px {
    height: 70px;
}

.height80px {
    height: 80px;
}

.height90px {
    height: 90px;
}

.height100px {
    height: 100px;
}

.height110px {
    height: 110px;
}

.height120px {
    height: 120px;
}

.height130px {
    height: 130px;
}

.height140px {
    height: 140px;
}

.height150px {
    height: 150px;
}

.heightAuto {
    height: auto;

}

.height100p {
    height: 100%;
}

.height90p {
    height: 90%;
}

.height85p {
    height: 85%;
}

.height80p {
    height: 80%;
}

.height70p {
    height: 70%;
}

.height60p {
    height: 60%;
}

.height50p {
    height: 50%;
}

.height45p {
    height: 45%;
}

.height40p {
    height: 40%;
}

.height30p {
    height: 30%;
}

.height20p {
    height: 20%;
}

.height10p {
    height: 10%;
}

.height5p {
    height: 5%;
}

.minHight40px{
    min-height: 40px;
}

.date_refresh {
    height: 100%;
}

.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border: none;
}

.foxybtn {
    border-radius: 5px !important;
    cursor: pointer;
    height: $foxybtn_height;
    font-size: $foxybtn_fontsize !important;

    .dx-button {
        border: 1px solid $foxymaincolour !important;
        border-radius: 10px
    }
}

.dx-texteditor.dx-editor-filled::after {
    border: none !important;
    border-radius: $textinput_borderradius !important;
}

.foxytextinput {
    .dx-texteditor-input-container {
        border: none !important;
    }

    input {
        border: $foxybtn_borderwidth solid $foxymaincolour;
        border-radius: $textinput_borderradius !important;
    }

    border: none;
    display: block;
    font-family: inherit;
    font-size: $foxymainfontsize !important;
    height: $textinput_height;
    margin: $textinput_margin;
    padding: 8px;
    transition: box-shadow 0.45s ease 0s,
    border-color 0.45s ease-in-out 0s;
}

.page {
    height: calc(100% - 100px);
    padding: 1% 3% 0px 3%;
}

.pagecontent {
    color: $foxytextcolour;
    overflow: hidden;
    padding-bottom: 50px;

    .compententBox {
        margin-top: 1%
    }
}

.scrollable {
    overflow: auto;

}

.header {
    height: 50px;
    background-color: $foxymaincolour !important;
    color: $foxyaccentcolour !important;

    .mainheading {
        h1 {
            font-size: 3vw;
            margin-bottom: unset;

            @media screen and (max-width: $small) {
                font-size: 5vw;
            }

            @media screen and (max-width: $medium) {
                font-size: 5vw;
            }
        }
    }

    .syncIcon {
        height: 100%;
        width: 100%;
        color: orangered;

        .dx-button {
            background-color: $foxymaincolour !important;
            color: orangered;
            height: 100%;
            width: 100%;

            .dx-icon {
                height: 100%;
                width: 100%;
                color: orangered;

                @media screen and (max-width: $small) {
                    font-size: 8vw;
                }

                @media screen and (max-width: $medium) {
                    font-size: 8vw;
                }
            }
        }
    }

    .onlineIcon {
        height: 100%;
        width: 100%;
        color: $foxymaincolour !important;

        .dx-button {
            background-color: $foxymaincolour !important;
            color: $foxymaincolour !important;
            height: 100%;
            width: 100%;

            .dx-icon {
                height: 100%;
                width: 100%;
                color: $foxymaincolour !important;

                @media screen and (max-width: $small) {
                    font-size: 8vw;
                }

                @media screen and (max-width: $medium) {
                    font-size: 8vw;
                }
            }
        }
    }

    .offlineIcon {
        height: 100%;
        width: 100%;
        color: red !important;

        .dx-button {
            background-color: $foxymaincolour !important;
            color: red !important;
            height: 100%;
            width: 100%;

            .dx-icon {
                height: 100%;
                width: 100%;
                color: red !important;

                @media screen and (max-width: $small) {
                    font-size: 8vw;
                }

                @media screen and (max-width: $medium) {
                    font-size: 8vw;
                }
            }
        }
    }

    .iconbtn {
        height: 100%;
        width: 100%;
        overflow: hidden;

        .dx-button {
            background-color: $foxymaincolour !important;
            color: $foxyaccentcolour !important;
            height: 100%;
            width: 100%;

            .dx-icon {
                height: 100%;
                width: 100%;
                color: $foxyaccentcolour !important;
            }
        }
    }
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
    opacity: .2 !important;
}

.iconColor {
    .dx-icon {
        color: $foxymaincolour !important
    }

    .dx-svg-icon {
        color: $foxymaincolour !important
    }
}

.iconBtnDelete {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .dx-button {
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
        border-bottom-right-radius: unset !important;
        border-top-right-radius: unset !important;

        .dx-icon {
            height: 100%;
            width: 100%;
            color: red !important;
        }

        .dx-svg-icon {
            color: red !important
        }
    }
}

.dx-svg-icon {
    font-size: 30px !important
}

.btnInfo {
    .dx-icon {
        color: $foxymaincolour !important
    }

    .dx-svg-icon {
        color: $foxymaincolour !important
    }
}

.heading {
    color: $foxymaincolour !important;
    font-weight: bold !important;
    font-size: $foxyPageHeadingfontsize !important
}

.heading-small-main {
    color: $foxymaincolour !important;
    font-weight: 500;
    font-size: $foxysmallfontsize;
}

.heading-small-black {
    color: black !important;
    font-weight: 500;
    font-size: $foxysmallfontsize;
}

.footer {
    height: 50px;

    .footerbtn {
        color: $foxymaincolour !important;
        border: 1px solid $foxymaincolour !important
    }
}

.clickedtab {
    color: $foxyaccentcolour !important;
    background-color: $foxymaincolour !important;
}

.center {
    justify-content: center;
    align-items: center;
    display: flex !important;
}

.left {
    display: flex !important;
    justify-content: start;
    align-items: CENTER;
}

.right {
    display: flex !important;
    justify-content: end;
    align-items: CENTER;
}

.bottom-end {
    justify-content: end;
    align-items: end;
    display: flex;
}

.dx-loadindicator {
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 9999;
    margin: auto;
}

.padding-right-5px {
    padding-right: 5px;
}

.padding-left-5px {
    padding-left: 5px;
}

.padding-right-10px {
    padding-right: 10px;
}

.padding-left-10px {
    padding-left: 10px;
}

.paddingTop6px {
    padding-top: 6px;
}

.paddingTop10px {
    padding-top: 10px;
}

.paddingBottom10px {
    padding-bottom: 10px;
}

.marginBottom5px {
    margin-bottom: 5px;
}

.marginBottom10px {
    margin-bottom: 10px;
}

.marginBottom20px {
    margin-bottom: 20px;
}

.marginBottom35px {
    margin-bottom: 35px;
}

.marginTop3p {
    margin-top: 3%;
}

.marginTop15px {
    margin-top: 15px;
}

.marginLeft10px {
    margin-left: 10px;
}

.dx-texteditor-input,
.dx-lookup-field {
    color: $foxytextcolour !important;
    padding-left: 5px !important;;
}

.dx-datebox {
    .dx-texteditor-input-container {
        width: 80%
    }

    .dx-texteditor-buttons-container {
        width: 10%
    }
}

.dx-lookup {
    .dx-lookup-field {
        width: 100%; 
        padding-right: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .dx-lookup-arrow {
        display: none
    }
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-heading {
    .dx-button {
        border: none !important;
        border-radius: 10px;

        .dx-button-content {
            border: none !important;
            border-radius: 10px;

            .dx-button-text {}
        }
    }

    .heading {
        .dx-button-text {
            font-weight: bold !important;
        }
    }
}

.coloredBtn {
    border: 1px solid $foxyaccentcolour !important;

    .dx-button-content {
        background-color: $foxymaincolour;

        .dx-button-text {
            color: $foxyaccentcolour
        }
    }
}

.reportBtn {
    width: 100%;
    color: white !important;
    font-weight: bold !important;
    font-size: 24px !important;
}

.mainBtn {
    border: 1px solid $foxymaincolour !important;
    border-radius: 5px;

    .dx-button-content {
        background-color: white;

        .dx-button-text {
            color: $foxymaincolour
        }
    }
}

.display-table {
    display: table !important;
}

.dx-popup-title {
    background-color: $foxymaincolour !important;
    color: $foxyaccentcolour !important;
}

.dx-popup-content {
    padding: 5px !important;

    .infoPopup {
        .infoPopupContent {
            padding: 5px !important;

            .label {
                color: $foxymaincolour !important;
                white-space: break-spaces;
            }
        }
    }
}

.collapsed {
    height: auto !important;

    .section {
        display: none !important;
    }
}

.not_started {
    background-color: #1817173d;

    .borderRight {
        border-color: #1817173d;
    }
}

.editing {
    background-color: #ff99006b;

    .borderRight {
        border-color: #ff99006b;
    }

}

.saved {
    background-color: #80000061;

    .borderRight {
        border-color: #80000061;
    }
}

.dumped {
    background-color: #00987278;

    .borderRight {
        border-color: #00987278;
    }
}

.NoReport {
    background-color: #000000;

    .deviceReportIcon {
        .dx-icon {
            color: #000000;
        }
    }
}

.DraftReport {
    background-color: #FF9900;

    .deviceReportIcon {
        .dx-icon {
            color: #FF9900;
        }
    }
}

.ReportComplete {
    background-color: #800000;

    .deviceReportIcon {
        .dx-icon {
            color: #800000;
        }
    }
}

.atMission {
    background-color: #c0d7eb70;

    .borderRight {
        border-color: #c0d7eb70;
    }

}

.none {
    display: none !important;
}

.reportBody {
    overflow-y: auto
}

.width100p {
    width: 100%
}

.eventTime {
    background-color: $foxymaincolour;
    color: $foxyaccentcolour;
    border-radius: 10px;
    font-weight: bold !important;
    font-size: 24px !important;
}

.dx-lookup-field-wrapper,
.dx-dropdowneditor-input-wrapper,
.dx-dropdowneditor-input-wrapper,
.dx-texteditor-input-container,
.dx-selectbox-container {
    border: none !important;

    .dx-label {
        overflow: auto;
    }
}

.dx-texteditor.dx-editor-outlined,
.dx-texteditor-input-container {
    border: none !important
}

.dx-texteditor-label {
    border: none !important;

    div {
        border: none !important;
    }

    .dx-label {
        span {
            color: $foxymaincolour !important;
            font-size: $foxymainfontsize !important;
        }
    }
}

.dx-textarea {
    color: $foxytextcolour;
    margin: 0px;
}

.dx-dropdowneditor-input-wrapper {
    .dx-tag-content {
        color: $foxymaincolour !important;
        background-color: white !important;
        border: 1px solid $foxymaincolour !important;
        font-size: $foxysmallfontsize;
        font-weight: normal;

        .dx-tag-remove-button {
            color: $foxymaincolour !important;
            background-color: white !important;
        }

        .dx-texteditor-buttons-container {
            .dx-icon {
                color: $foxymaincolour !important;
                background-color: white !important;
            }

            .dx-dropdowneditor-button {
                color: $foxymaincolour !important;
                background-color: white !important;

                .dx-dropdowneditor-icon {

                    color: $foxymaincolour !important;
                }
            }
        }
    }
}

.dx-texteditor-buttons-container {

    .dx-icon-clear {
        justify-content: center;
        align-items: center;
        display: flex !important;
        background-color: unset !important;
    }

    .dx-icon-clear::before {
        justify-content: center;
        align-items: center;
        display: flex !important;
        position: unset !important;
        margin: unset !important;
        color: $foxymaincolour;
        font-size: $foxymainfontsize;
    }
}
.dx-tag-container{
    .dx-texteditor-input{
        background: unset
    }
}
.dx-dropdowneditor-icon{
    color: $foxymaincolour;
    font-size: $foxymainfontsize;  
}
.dx-texteditor-input {
    color: $foxytextcolour;
    font-size: $foxysmallfontsize;
    font-weight: normal;
}

.dx-label {
    span {
        font-size: $foxysmallfontsize;
        font-weight: normal;
        z-index: 99999;
        height: auto !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        overflow: visible;
    }
}

.dx-list-select-all {
    display: none;
}
 
.dx-lookup-field,
.dx-texteditor-input {
    padding-left: 5px !important;
    font-size: $foxymainfontsize !important;
    font-family: $foxtmanfontfamily !important;
}

.fieldBorder {
    border: $foxybtn_borderwidth solid $foxymaincolour;
    border-radius: 5px;
    padding: 10px;
}

.field {
    background-color: #f3efef61;
    font-size: $foxymainfontsize !important;

    .dx-texteditor-input-container {
        padding: 0px !important;
    }
}

.colourLableSemi {
    color: $foxymaincolour;
    font-size: $foxymainfontsize;
    font-weight: normal;
    margin-left: 10px;
}

.signature {
    border: $foxybtn_borderwidth solid $foxymaincolour;
    border-radius: 10px;
    min-height: 200px;
    width: 400px;
    height: 200px;
    .sigCanvas {
        width: 100% !important;
        height: 100% !important;
    }
}

.borderMainColor {
    border: $foxybtn_borderwidth solid $foxymaincolour;
}

.boxHeading {
    color: $foxymaincolour;
    font-size: $foxymainfontsize;
    border-bottom: 1px solid $foxymaincolour;
}

.mainbox {
    .boxline:nth-child(odd) {
        background-color: $foxyaccentcolour;
    }
}

.popupContents{
    display: flex !important;
    flex-direction: column !important;
}

.table-column{
    display: table-column !important;
}