// General Foxy vars
$small: 600px;
$medium: 900px;
$foxymaincolour: #009872;
$foxyaccentcolour: #f3efef;
$foxytextcolour: #333;
$foxysmallfontsize: 16px;
$foxymainfontsize: 20px;
$foxyPageHeadingfontsize: 24px;
$foxtmanfontfamily: Gotham A,Gotham B,Verdana,Arial,sans-serif;
// button veriables
$foxybtn_height: 40px;
$foxybtn_borderradius:20px;
$foxybtn_borderwidth: 1px;
$foxybtn_fontsize: 16px;
$foxybtn_margin: 5px;
// text input
$textinput_height: 40px;
$textinput_borderradius: 10px;
$textinput_borderwidth: 1px;
$textinput_margin: 5px;
